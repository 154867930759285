

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/qui-sont-meilleurs-avocats-criminels-quebec-alcool-volant.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const BlogPost32 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Conduite avec les facultés affaiblies et alcool au volant : Comparez les Avocats Criminels - Soumissions Avocat"
        description="Vous êtes accusé d’avoir conduit avec les facultés affaiblies? Vous souhaitez éviter une condamnation pour éviter les graves conséquences d’un casier judiciaire? Faites affaire avec des avocats spécialisés en dossiers d’alcool au volant avec l’aide de Soumissions Avocat!"
        image={LeadImage}>
        <h1>Conduite avec les facultés affaiblies et alcool au volant : Comparez les Avocats Criminels</h1>

					
					
						<p>Une accusation pour conduite avec les facultés affaiblies s’avère lourde de conséquences. Il ne suffit que d’un verre de trop pour que votre avenir si bien tracé ne prenne un changement de direction drastique! Avec raison, <strong>la loi prévoit des</strong> <strong>conséquences et sanctions importantes</strong> pour quiconque se voit reconnu coupable de conduite avec les facultés affaiblies!</p>
<p><StaticImage alt="Comparer avocats pour alcool au volant" src="../images/qui-sont-meilleurs-avocats-criminels-quebec-alcool-volant.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>Cela ne signifie pas que vous êtes sans défense pour autant!</strong> Dans le système de justice canadien, tout le monde a droit à une défense juste et équitable par un avocat de son choix. Commettre une erreur de jugement ne vous retire pas votre droit au respect de votre personne, loin de là<strong>! Si vous vous êtes fait arrêter pour conduite avec les facultés affaiblies, demandez à un avocat en droit criminel de vous représenter!</strong> C’est votre meilleure chance de vous en sortir avec un minimum de conséquences.</p>
<h2>À part l’alcool, quelles sont les autres substances qui peuvent mener à des accusations?</h2>
<p>Le Code criminel est sans équivoque à l’égard des accusations de conduite avec les facultés affaiblies : elles ne visent pas seulement l’alcool, mais <strong>tous les types de drogues et substances, incluant les médicaments!</strong> Vous avez bien lu, la loi ne se limite pas à punir les fêtards qui ont pris un verre de trop, mais également les gens qui sont sous l’influence de médicaments les empêchant de conduire de manière sécuritaire.</p>
<p>En ce qui concerne les médicaments, il est donc primordial de vérifier adéquatement avec votre médecin qu’ils ne vous empêchent pas de conduire après les avoir ingérés, mais surtout, qu’ils ne causent pas d’effets amplifiants lorsque mélangés avec d’autres substances comme l’alcool.</p>
<p><strong>Puis-je être accusé si j’ai consommé du Cannabis? Tout à fait!</strong> La légalisation du cannabis a peut-être eu pour effet de permettre la consommation récréative et médicale, mais en aucun cas cela n’a permis à un consommateur de prendre le volant.</p>
<p>Les policiers sont d’ailleurs en mesure, depuis peu, de contraindre une personne soupçonnée d’être sous l’influence du cannabis de soumettre un échantillon sanguin lorsque cette dernière aura échoué l’épreuve de coordination des mouvements ou le test salivaire.</p>
<h2>Quelles sont les principales lois entourant l’alcool au volant?</h2>
<p>Au Québec, deux lois sont d’un grand intérêt en matière d’alcool au volant et de conduite avec les facultés affaiblies. Il s’agit du Code criminel ainsi que du Code de la sécurité routière. Si vous n’aviez jamais eu l’occasion de vous familiariser avec ces deux lois, vous verrez qu’une accusation pour alcool au volant vous fera rattraper cette lacune assez rapidement!</p>
<p><strong>Le Code criminel</strong> est la loi qui prévoit les sanctions les plus graves en cas de verdict de culpabilité. Il est prévu dans ce même code qu’une personne reconnue coupable d’avoir conduit avec <strong>un taux d’alcoolémie supérieur à 80mg d’alcool par 100ml de sang </strong>se verra imposer une <strong>amende minimale de 1000$</strong> en plus de se voir imposer un casier judiciaire.</p>
<p><StaticImage alt="Test d’alcoolémie accusations conduit facultés affaiblies" src="../images/avocat-droit-criminel-alcool-volant-conduite-facultes-affaiblies.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Des peines encore plus sévères sont prévues en cas de récidive et peuvent aller jusqu’à l’emprisonnement minimal de 30 jours et en cas de double récidive, d’emprisonnement minimal de 120 jours. Vous aurez le temps de « dégriser » un peu si les premières arrestations n’étaient pas suffisantes.</p>
<p><strong>En cas de préjudice causé à autrui, ce sont des accusations encore plus sérieuses de conduite dangereuse ou même d’homicide involontaire qui pourraient s’ajouter à la liste.
</strong>
<strong>Le Code de la sécurité routière </strong>quant à lui prévoit des sanctions qui auront le plus grand impact au quotidien, soit la <strong>suspension de votre permis</strong> ainsi que les conditions nécessaires pour le récupérer!</p>
<h2>Les conséquences d’une condamnation pour conduite avec les facultés affaiblies</h2>
<p>Lorsque vous vous faites arrêter par un policier et que celui-ci vous arrête pour conduite avec les facultés affaiblies, vous n’êtes pas reconnu coupable automatiquement. Comme il s’agit d’un crime, la couronne devra prouver votre <strong>culpabilité hors de tout doute raisonnable</strong> comme pour toute infraction similaire.</p>
<p>Cependant, entre la date d’arrestation et celle du jugement, des conséquences seront tout de même appliquées par la Société de l’assurance automobile (S.A.A.Q.) et varieront en fonction de la gravité des accusations portées.</p>
<p><strong>Perte ou suspension du permis de conduire :</strong> Avant même d’avoir été reconnu coupable, le Code de la sécurité routière prévoit une sanction automatique au contrevenant, soit la <strong>suspension du permis pour une durée de 90 jours</strong>. Cela prendra effet avant même que votre cause ne soit amenée devant le juge!</p>
<p><strong>Saisie du véhicule :</strong> Une saisie automatique du véhicule est également prévue lorsque votre taux d’alcoolémie dépasse du double le seuil de la limite permise par la loi, soit plus de 16 mg d’alcool par 100 ml de sang. Cela vise à garantir qu’un tel automobiliste soit mis hors d’état de nuire.</p>
<p><strong>Casier judiciaire et amende :</strong> Vous êtes accusé d’avoir commis un crime, alors vous serez inévitablement trainé en cour afin de présenter votre défense à ces accusations. Qu’arrive-t-il en cas de culpabilité? Vous serez maintenant l’heureux titulaire d’un casier judiciaire condamné à verser une amende d’au moins 1000$.</p>
<p><strong>Interdiction de conduire :</strong> Si vous êtes reconnu coupable, vous serez affligé d’une interdiction de conduire pour une période de 1 an. Cette période peut même être de 3 ans si votre taux d’alcoolémie dépassait le double de la limite permise. En plus, il vous faudra passer au travers des programmes de réhabilitation prévus par la S.A.A.Q. pour récupérer votre permis de conduire.</p>
<p>Accusations criminelles supplémentaires et peine de prison! Les peines ci-haut mentionnées ne sont que les conséquences minimales auxquelles vous pouvez vous attendre en cas de culpabilité. S’il fallait que votre geste ait causé du tort à une autre personne, la liste des accusations s’en verra d’autant plus allongée!</p>
<p><strong>Heureusement pour vous, les accusations de conduite avec les facultés affaiblies font partie du quotidien des avocats criminalistes du Québec. Ils sont donc votre meilleure chance de vous en sortir indemne et c’est la raison pour laquelle vous ne devez pas tarder à en engager un!</strong></p>
<h2>Quelles sont les défenses contre des accusations d’alcool au volant?</h2>
<p>Il va de soi que le droit ne laisse pas les accusés sans défense, quel que soit le crime commis. L’alcool au volant ne fait pas exception à ce principe et plusieurs défenses peuvent être soulevées par votre avocat criminaliste pour excuser complètement votre geste ou, du moins, pour minimiser ses conséquences. Voici donc où se trouvent vos meilleures chances de succès!</p>
<p><StaticImage alt="Quelles défenses invoquer lors d’accusations d’alcool au volant?" src="../images/defenses-contre-accusations-conduite-facultees-affaiblies-avocat-quebec.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Défenses du « dernier verre » et du « verre d’après » : des défenses désuètes!</strong> De prime abord, parlons des défenses que vous ne pouvez plus invoquer, peu importe ce que votre oncle habitué à ce genre d’accusations puisse vous dire. La défense du dernier verre permettait à un accusé de s’exonérer en affirmant avoir pris un verre tout juste avant de prendre le volant et, qu’en raison de ce court délai, il n’avait pas encore métabolisé l’alcool consommé, le rendant ainsi apte à conduire.</p>
<p>En revanche, la défense du verre d’après consiste à affirmer après avoir pris le volant, mais préalablement au test de dépistage. Dans un cas comme dans l’autre, ces défenses sont devenues inadmissibles.</p>
<p><strong>Interception illégale par la police :</strong> Bien que les policiers aient un pouvoir de contrôle sur les véhicules circulant sur le chemin public, ces derniers doivent tout de même avoir un motif pour intercepter un véhicule, tels que :</p>
<ul>
<li>Le contrôle de la sécurité routière, </li>
<li>La défectuosité mécanique du véhicule, </li>
<li>La vérification du permis</li>
<li>La vérification de sobriété
</li>
</ul>
<p>Si vous parvenez à démontrer que le policier vous a intercepté sans motif raisonnable, il deviendra alors possible de rendre les tests d’alcoolémie inadmissibles.</p>
<p><strong>
Négation du droit à un avocat :</strong> Lors d’une arrestation, un policier doit vous informer de votre droit aux services d’un avocat et est même tenu de vous aider à en contacter un. Si un agent de la paix omet de vous informer de ce droit ou vous le nie complètement, les accusations pourraient être abandonnées.</p>
<p><strong>Arrestation sans motif raisonnable :</strong> Tout comme l’interception, l’arrestation d’un individu pour un crime allégué doit être basée sur des motifs raisonnables et concrets. Par ailleurs, le fardeau de prouver ces qualités revient à la couronne le temps venu d’aller en procès!</p>
<p><strong>Absence de preuve hors de tout doute raisonnable!</strong> Le meilleur moyen de faire tomber une accusation criminelle quelconque est de semer le doute dans la tête du juge. Cela se fera en attaquant les vices de procédure policières, les motifs d’interception et la fiabilité des tests d’alcoolémie.</p>
<p><strong>Comme la condamnation doit être basée sur une preuve de culpabilité hors de tout doute raisonnable, le rôle de votre avocat en défense sera de trouver les failles dans la preuve présentée contre vous. </strong></p>
<h2>Alcool au volant : les mythes vs. la réalité!</h2>
<p>Ce sont des centaines de Québécois qui se font arrêter chaque année pour cause de conduite avec les facultés affaiblies. C’est donc dire qu’à peu près tout le monde connaît de près ou de loin une personne s’étant fait prendre en pareilles circonstances. Un corollaire inévitable de cet état des choses est la propagation de mythes et faussetés que nous mettons en lumière pour vous!</p>
<h2><strong>Si je ne souffle pas, je ne peux pas être condamné!</strong></h2>
<p><strong>Faux!</strong> Refuser de vous soumettre au test d’alcoolémie ordonné par les policiers est très lourd de conséquences. Si vous pensiez vous en tirer en refusant simplement le test, détrompez-vous; vous pourriez empirer votre situation. En effet, un tel refus est en fait une infraction criminelle.</p>
<p><StaticImage alt="Alcool au volant mythes dormir dans son auto" src="../images/mythes-realite-accusations-alcool-volant-refus-souffler-test.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Vous pourrez donc être amené en cour pour répondre de ces accusations et écoper d’une amende de plus de 2000$ en cas de culpabilité. Non seulement cela, mais votre refus permettra également au juge de tirer des présomptions quant à votre niveau d’intoxication, ce qui aidera la couronne à faire la preuve de votre conduite avec les facultés affaiblies.</p>
<h2><strong>Si je dormais dans mon auto en état d’ébriété, je ne commets pas d’infraction!</strong></h2>
<p><strong>Faux!</strong> L’article du Code criminel condamnant l’alcool au volant prévoit également la notion de « <strong>garde et contrôle du véhicule en état d’ébriété</strong> ». Cela signifie qu’un individu qui décide de dormir ou d’attendre dans son véhicule alors qu’il est intoxiqué par l’alcool s’expose lui aussi à des accusations criminelles.</p>
<p>Il vaut donc mieux prévoir un plan de raccompagnement mieux réfléchi que celui d’attendre sur la banquette arrière, car ce moment d’attente pourrait vous couter un casier judiciaire. Prendre le taxi est moins cher à tout coup, croyez-nous!</p>
<p>Des défenses existent pour exonérer la personne accusée d’avoir eu la garde et le contrôle d’un véhicule en état d’ébriété, comme le fait de prouver l’absence d’intention de conduire, l’attendre d’un taxi et autres démarches du genre, mais le plus simple est se tenir loin de son véhicule après avoir consommé quelques verres.</p>
<h2>Si mon taux d’alcoolémie ne dépasse pas le « 0.08 », je peux repartir comme si de rien n’était!</h2>
<p><strong>Faux!</strong> Le taux d’alcoolémie légal de 80 mg d’alcool par 100 millilitres de sang constitue un crime, mais cela ne signifie pas que vous n’avez pas commis l’infraction de conduite avec les facultés affaiblies. Comme des épreuves de coordination peuvent vous être exigées et que des tests de salive peuvent être prélevés, vous n’êtes pas sorti du bois si ces tests ne sont pas concluants.</p>
<p>D’ailleurs, un policier pourra vous mettre en état d’arrestation s’il juge que vous conduisez sous l’effet d’une drogue ou du mélange de drogues et d’alcool vous rendant dangereux à la circulation. Le fameux « 0.08 » est donc loin d’être une mesure absolue.</p>
<h2>Le test d’alcoolémie (la baloune) est le seul élément de preuve admis!</h2>
<p><strong>Encore faux! </strong>Les témoignages de policiers, leurs observations lors de l’arrestation, les tests de salive et de coordination ainsi qu’une foule d’autres éléments de preuve pénale peuvent être admis pour prouver votre culpabilité.</p>
<p><strong>Ne l’oubliez pas, vous êtes accusé d’un crime lorsque vous êtes arrêté pour alcool au volant! Ne vous surprenez donc pas que l’on prenne tous les moyens pour vous condamner et prenez la peine d’engager un avocat spécialisé dans ce type d’infraction!
</strong></p>
<h2>10 raisons d’engager un avocat en droit criminel en cas de conduite avec les facultés affaiblies?</h2>
<p>Vous êtes sur le point de monter sur le banc des accusés et vous vous demandez toujours pourquoi il est aussi important d’engager un spécialiste du droit criminel pour de simples accusations de conduite avec les facultés affaiblies?</p>
<p><strong>Vous aurez le temps d’y penser en attendant l’autobus une fois votre permis révoqué, mais d’ici là, voici le top 10 des meilleures raisons d’engager un avocat criminaliste!
</strong>
<strong>1. Meilleures chances de vous voir exonéré!</strong></p>
<p>Qui dit spécialiste dit chances de succès plus élevées. Après avoir réglé des dizaines de cas comme le vôtre, un avocat criminaliste sera votre chance de succès la plus sure!</p>
<p><strong>2. Diminution de la peine</strong></p>
<p>En plaidant des circonstances atténuantes ou autres défenses avec succès vous pourriez obtenir une peine diminuée!</p>
<p><strong>3. Plus de facilité à récupérer votre permis</strong></p>
<p>Récupérer son permis à la S.A.A.Q. n’est pas chose facile, d’autant plus que la suspension est automatique en pareilles circonstances.</p>
<p><strong>4. Une négociation de peine réduite en cas de récidive</strong></p>
<p>La récidive de conduite avec les facultés affaiblies mène en prison! Si tel est votre cas, vaut mieux payer pour les services d’un criminaliste que d’épargner quelques sous derrière les barreaux.</p>
<p><strong>5. Des services spécialisés pour ce type d’infraction</strong></p>
<p>Invoquer les défenses d’alcool au volant avec succès n’est pas chose facile. Il faut choisir le bon angle pour convaincre un juge.</p>
<p><strong>6. Une connaissance pointue des lois pertinentes</strong></p>
<p>Les failles de la loi sont les meilleures amies des criminalistes. Encore faut-il les connaître en matière criminelle pour en profiter.</p>
<p><strong>7. Une expérience de succès qui vous donnera confiance</strong></p>
<p>Un procès criminel prend du temps à se régler et si vous confiez votre démarche à n’importe quel avocat, vous verrez que l’angoisse d’une défaite le fera paraître encore plus long.</p>
<p><strong>8. Une estimation honnête de vos chances de succès</strong></p>
<p>Après avoir vu défiler les infractions similaires à la vôtre année après année, votre avocat vous dira d’entrée de jeu quelles sont vos chances de succès.</p>
<p><strong>9. Un service 24/7 pour les urgences</strong></p>
<p>Les avocats criminalistes qui prennent les causes d’alcool au volant se tiennent prêts en tout temps pour venir en aide aux accusés.</p>
<p><strong>10. Des habilités de plaidoirie hors pair!</strong></p>
<p>Les criminalistes sont des plaideurs exceptionnels, compétence nécessaire pour exonérer leurs clients. Engager un criminaliste, c’est profiter d’une telle expertise. <strong>Votre liberté vous en remerciera!</strong></p>
<h2>Soumissions Avocat vous aide à trouver les avocats en droit criminel en cas d’alcool au volant!</h2>
<p><StaticImage alt="Qui sont les meilleurs avocats criminels pour alcool au volant?" src="../images/trouver-comparer-engager-services-avocat-alcool-au-volant.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Les accusations d’alcool au volant ou de conduite avec les facultés affaiblies sont sérieuses et méritent d’être prises en charge par des professionnels du droit qualifiés.  Pour cette raison, vous devez absolument confier votre démarche aux meilleurs criminalistes qui soient!</p>
<p><strong>Ces criminalistes, c’est avec Soumissions Avocat que vous les trouverez! Notre réseau de partenaires avocats compte les meilleurs juristes de la province aptes à prendre votre dossier en charge.</strong></p>
<p><strong>Pour être mis en contact avec un avocat de votre région en droit criminel, remplissez le formulaire et vous serez rapidement mis en contact avec l’un d’eux!</strong></p>
    </SeoPage>
)
export default BlogPost32
  